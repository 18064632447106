import React from 'react'
import styled from 'styled-components'



const Footer = styled.div`

.es-c-footer {
  // 🎛
  width: 100%;
  background-color: #0033a1;
  .es-g-footer{
  // 🎨
  margin: 0 auto;
  max-width: 1360px;
  // 
  font-family: 'Open Sans';
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  // 📰
  display: grid;
  grid-template-rows: 35px;
  grid-template-columns: 1fr;
  grid-template-areas:
  "ga_copyright ga_privacy ga_terms ga_contact";

  .copyright {
    grid-area: ga_copyright; // 🗺
    justify-self: start;
    align-self: center;
  }
  .privacy {
    grid-area: ga_privacy; // 🗺
    justify-self: end;
    align-self: center;
    margin-right: 10px;
  }
  .terms {
    grid-area: ga_terms; // 🗺
    justify-self: end;
    align-self: center;
    margin-right: 10px;
  }
  .contact {
    grid-area: ga_contact; // 🗺
    justify-self: end;
    align-self: center;
    margin-right: 10px;
  }
  }
}




`

const FooterComponent = () => {
  return (
    <Footer>
      <div className="es-c-footer">
        <div className="es-g-footer">

        <div className="copyright"> © 2019 Echo Global Logistics, all rights reserved </div> 
        <div className="privacy">Privacy</div>
        <div className="terms">Terms and Conditions</div>
        <div className="contact">Contact Us</div>

        </div>
      </div>
    </Footer>  
  )
}

export default FooterComponent;