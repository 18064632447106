import React from "react";
import { Modal } from "semantic-ui-react";
import '../../styles/modal.css';

import styled from "styled-components";

import ModalButton from '../../components/Buttons/ModalButtons/ModalButton.js'
import MoleculePickUpNotification from '../../Molecules/MoleculePickupNotification.js'


// 💅
const ModalCreateNewLocation = styled.div`
  /* border-radius: 4px;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5); */
  max-width: 960px;
`;

const ModalGrid = styled.div`
  max-width: 960px;
  // 📰
  display: grid;
  grid-template-rows: 0.2fr 1fr 0.2fr;
  grid-template-columns: 0.3fr 0.7fr;
  grid-template-areas:
    "g_modal_header g_modal_header"
    "g_modal_content_left g_modal_content_right"
    "g_modal_footer g_modal_footer";
`;

// MODAL GRID: HEADER
const ModalHeader = styled.div`
  grid-area: g_modal_header; // 🗺
  min-height: 85px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: solid 2px #e7edf0;

  .ModalTitle {
    font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    text-align: center;
    padding-top: 20px;
  }
`;

// MODAL GRID: CONTENT LEFT
const ModalGridContentLeft = styled.div`
  grid-area: g_modal_content_left; // 🗺
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;

  .Title {
    margin: 0;
    font-family: "Open Sans",sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
    line-height: 1.5rem;
  }

  .SubText {
    margin: 0;
    font-family: "Open Sans",sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
    padding-top: 20px;
    line-height: 1.5rem;
  }
`;

// MODAL GRID: CONTENT RIGHT
const ModalGridContentRight = styled.div`
  grid-area: g_modal_content_right; // 🗺
  border-left: solid 2px #e7edf0;
  padding: 15px;
  .form-input.input-lg.input-message {
    height: 100px;
  }
`;

// MODAL GRID: FOOTER
const ModalFooter = styled.div`
  grid-area: g_modal_footer; // 🗺
  border-top: solid 2px #e7edf0;
`;

const FooterWrap = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

`;

const ButtonConfirmLocation = styled.button`
  font-family: 'Open Sans';
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  background-color: #26c1fb;
  border: 0.1rem solid #26c1fb;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px !important;
  display: inline-block;
  height: 45px;
  letter-spacing: 0rem;
  padding: 0 1.5rem;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:focus,
  &:hover {
    opacity: 0.9;
    color: #fff;
    outline: 0;
    background-color: #04abea;
    border: 0.1rem solid #04abea;
  }
`;

const ModalModalShare = props => (
  <Modal trigger={<ModalButton shareOrder>SHARE</ModalButton>}>
    <ModalCreateNewLocation>
      <ModalGrid>
        <ModalHeader>
          <div className="ModalTitle">Share Quote Details Via Email</div>
          <div className="Exit" />
        </ModalHeader>

        <ModalGridContentLeft>
          <div className="Title">
            Add up to 10 recipients to email the Quote Details
          </div>
          <div className="SubText">
            Add your own email address as a recipient to email the Quote Details
            to yourself.
          </div>
        </ModalGridContentLeft>

        <ModalGridContentRight>
          <div className="AddAnotherEmail">
            <MoleculePickUpNotification />
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                Subject
              </label>
              <input
                className="form-input input-lg"
                type="text"
                placeholder="Your Shipping Quote From echo (Quote 2493990)"
              />
            </div>

            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                MESSAGE
              </label>
              <input
                className="form-input input-lg input-message"
                type="text"
                placeholder=""
              />
            </div>
          </div>
          <div className="InputAddress2" />
        </ModalGridContentRight>

        <ModalFooter>
          <FooterWrap>
            <ButtonConfirmLocation>SEND QUOTE DETAILS</ButtonConfirmLocation>
          </FooterWrap>
        </ModalFooter>
      </ModalGrid>
    </ModalCreateNewLocation>
  </Modal>
)
export default ModalModalShare