import React from "react"
import styled from "styled-components"

const ModalButtonShare = styled.button`
  font-family: "Open Sans";
  background-color: ${props =>
    props.newLocation
      ? "transparent"
      : props.shareOrder
      ? "#a9adaf"
      : "transparent"};
  border: 0.1rem solid
    ${props =>
      props.newLocation
        ? "transparent"
        : props.shareOrder
        ? "#a9adaf"
        : "transparent"};
  border-radius: 3px;
  color: ${props =>
    props.newLocation
      ? "#0033a1"
      : props.shareOrder
      ? "#ffffff"
      : "transparent"};
  cursor: pointer;
  float: left;
  font-size: ${props => (props.shareOrder ? "14px !important" : "0.875rem")};
  font-weight: 700;
  height: 45px;
  letter-spacing: 0rem;
  padding: 0 3.5rem;
  text-align: center;
  text-decoration: ${props => (props.shareOrder ? "none" : "none")};
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:focus,
  &:hover {
    opacity: 0.9;
    color: ${props =>
      props.newLocation ? "#FFFFFF" : props.shareOrder ? "#ffffff" : "#26c1fb"};
    outline: 0;
    background-color: ${props =>
      props.newLocation ? "#26c1fb" : props.shareOrder ? "#8f9496" : "#26c1fb"};
    border: 0.1rem solid
      ${props =>
        props.newLocation
          ? "#26c1fb"
          : props.shareOrder
          ? "#8f9496"
          : "#26c1fb"};
    text-decoration: ${props =>
      props.newLocation ? "none" : props.shareOrder ? "none" : "#26c1fb"};
  }
`

const Button = ({ newLocation, shareOrder, ...props }) => {
  return (
    <ModalButtonShare newLocation={newLocation} shareOrder={shareOrder} {...props} />
  )
}
export default Button
