import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// import ButtonSave from '../../components/Buttons/ButtonSave.js'
// import ButtonShare from '../../components/Buttons/ButtonShare.js'

const OrganismCarrierResultsQuoteSummary = styled.div`

    display: grid;
    grid-template-rows: 0.2fr 0.5fr 0.5fr 0.2fr 0.5fr;
    grid-template-columns: minmax(1fr, 1fr) 1fr 1fr;
    grid-template-areas:
    "sg_title sg_title sg_title"
    "sg_carrier_selected_image sg_carrier_selected_image sg_btn_remove"
    "sg_title_cargo_liability sg_title_transit_time sg_title_price"
    "sg_underline sg_underline sg_underline"
    "sg_book_shipment sg_book_shipment sg_book_shipment";
    
    .carrier-selected-image {
      grid-area: sg_carrier_selected_image;
      align-self: center;
    }
    .ButtonSave {
      grid-area: sg_btn_save;
    }
    .ButtonShare {
      grid-area: sg_btn_share;
    }
`;


const SgTitle = styled.p`
  grid-area: sg_title;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  font-size: 30px;
  color: #141b1f;
`;

const SgButtonRemove = styled.button`
  grid-area: sg_btn_remove;
  color: #666;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px !important;
  background-color: transparent;
  border: transparent;
  align-self: center;
`;

// Titles + Content
const SgTitleCargoLiability = styled.p`
  grid-area: sg_title_cargo_liability;
  font-size: 12px;
  color: #2b363b;
  /* text-align: right; */
  margin-bottom: 10px;
  font-weight: 400;
`;
const SgNew = styled.p `
  font-size: 14px;
  font-weight: 600;
  color: #666;
  span {
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
    font-weight: 400;
  }
`;
const SgUsed = styled.p `
  font-size: 14px;
  font-weight: 600;
  color: #666;
  span {
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
    font-weight: 400;
  }
`;

const SgTitleTransitTime = styled.p`
  grid-area: sg_title_transit_time;
  justify-self: center;
  font-size: 12px;
  color: #2b363b;
  margin-bottom: 10px;
`;
const SgEstDelivery = styled.p `
  justify-self: center;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  span {
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
    font-weight: 400;
  }
`;
const SgTitlePrice = styled.p`
  grid-area: sg_title_price;
  justify-self: end;
  font-size: 12px;
  color: #2b363b;
  margin-bottom: 10px;
`;
const SgPrice = styled.p `
  font-size: 14px;
  font-weight: 600;
  color: #666;
`; 



const SgUnderline = styled.div`
  grid-area: sg_underline;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e7edf0;
`;


const ButtonBookShipment = styled.button`
  grid-area: sg_book_shipment;
  grid-column-start: span 3;
  font-family: 'Open Sans';
  background-color: #3daf2c;
  border: 0.1rem solid #3daf2c;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 14px !important;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.02rem;
  padding: 0 2rem;
  align-items: stretch;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // removes default border highlighting 
  &:focus {
    outline: -webkit-focus-ring-color none;
  }
  a {
    color: #fff;
  }
  a:hover {
    color: #fff;
  }
`;

const EchoShipOrganismComponent = (props) => {
  return (

    <OrganismCarrierResultsQuoteSummary>

        <SgTitle>{props.summaryTitle}</SgTitle>

        <img
            className="carrier-selected-image"
            src={props.image} 
        />
        <SgButtonRemove>REMOVE</SgButtonRemove>

        <SgTitleCargoLiability>Cargo <br />Liability
            <SgNew>$300.00 <br />
            <span>New</span>
            </SgNew>
            <SgUsed>$300.00 <br />
            <span>Used</span>
            </SgUsed>
        </SgTitleCargoLiability>
        <SgTitleTransitTime>Transit Time
            <SgEstDelivery> 4 Days <br />
            Fri, Mar 22 <br />
            <span>Est. Delivery</span>
            </SgEstDelivery>
        </SgTitleTransitTime>
        <SgTitlePrice>Price
            <SgPrice>$207.32</SgPrice>
        </SgTitlePrice>

        <SgUnderline></SgUnderline>

            <ButtonBookShipment> 
                <Link to="/quote/RouteAndSchedule/">Book Shipment</Link>
            </ButtonBookShipment>


        {/* <ButtonSave
          buttonText="Save"
        />
        <SgButtonWhiteSpace></SgButtonWhiteSpace>

        
        <ButtonShare>
                  
        </ButtonShare> */}


    </OrganismCarrierResultsQuoteSummary>

  )
}

export default EchoShipOrganismComponent;

