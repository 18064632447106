// font-size: 
// ${props => {
//   if (props.big) return '20px'
//   return '16px'
// }};


import React from 'react'
import styled from 'styled-components'

// 💅
const PrimaryButtonTwo = styled.button `
  font-family: 'Open Sans' !important;
  background-color: 
  // 🎭 
    ${props => (props.orange ? '#F55700' :
                props.green ? '#3daf2c' : 
                props.login ? '#26c1fb' : 
    '#26c1fb')};
  border: 0.1rem solid 
    // 🎭 
    ${props => (props.orange ? '#F55700' :
                props.green ? '#3daf2c' : 
                props.login ? '#26c1fb' : 
    '#26c1fb')};
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  height: 42px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 6px 26px;
  text-align: center;
  text-decoration: none;
  margin-right: ${props => 
        (props.orange ? '0px' : props.green ? '0px' :
          props.login ? '0px' : '0px')};
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:focus,
    &:hover {
      opacity: 0.9;
      color: #fff;
      outline: 0;
      background-color: 
      // 🎭 
      ${props => 
        (props.orange ? '#DE4A00' : props.green ? '#3daf2c' :
          props.login ? '#26c1fb' : '#26c1fb')};
      border-color: 
      // 🎭 
      ${props => 
        (props.orange ? '#DE4A00' : 
        props.green ? '#3daf2c' : 
        props.login ? '#26c1fb' : 
      '#26c1fb')};
  }
`

const Button = ({ orange, green, login, ...props }) => {
  return (
      <PrimaryButtonTwo
          orange={orange}
          green={green}
          login={login}
          {...props}
      />
  )
}
export default Button;