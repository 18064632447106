import React from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css'

import { Link } from 'gatsby'

const SecondaryNav = styled.div`

  // SECONDARY NAVBAR
  .es-c-navbar--secondary {
    // 🎛
    height: 2.2rem;
    // 🎨
    background-color: #0033a1;
    // 
    font-family: 'Open Sans';
    color: #fff;

    font-weight: 300;
    letter-spacing: 0.2px;
    .navbar-wrapper {
      margin: 0 auto;
      max-width: 1360px;
    // 📰
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    grid-template-areas:
    "ga_dropdownOne ga_dropdownTwo";

      .dropdownOne {
        grid-area: ga_dropdownOne; // 🗺
        justify-self: end;
        align-self: center;
      }
      .dropdownTwo {
        grid-area: ga_dropdownTwo; // 🗺
        justify-self: end;
        align-self: center;
        margin-left: 10px;
      }
    }
  }
// New Styles
.btn.btn-link {
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
}
  .es-c-navbar--secondary .menu .menu-item a {
  color: #666;
  font-weight: 600;
  padding: 10px;
  border-bottom: solid 1px #e7edf0;
  &:hover {
    color: #26c1fb;
    background-color: #f5f5f5;
  }
}
.es-c-navbar--secondary .menu .menu-item .location a {


text-align: right;


}
  // Dropdown
.dropdown {
  display: inline-block;
  position: relative;

  .menu {
    animation: slide-down .15s ease 1;
    display: none;
    left: 0;
    max-height: 50vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
  }

  &.dropdown-right {
    .menu {
      left: auto;
      right: 0;
    }
  }

  &.active .menu,
  .dropdown-toggle:focus + .menu,
  .menu:hover {
    display: block;
  }

  // Fix dropdown-toggle border radius in button groups
  .btn-group {
    .dropdown-toggle:nth-last-child(2) {
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }
}
// Menus
.menu {
  @include shadow-variant(.05rem);
  background: $bg-color-light;
  border-radius: $border-radius;
  list-style: none;
  margin: 0;
  min-width: $control-width-xs;
  padding: $unit-2;
  transform: translateY($layout-spacing-sm);
  z-index: $zindex-3;

  &.menu-nav {
    background: transparent;
    box-shadow: none;
    
  }

  .menu-item {
    margin-top: 0;
    padding: 0 $unit-2;
    position: relative;
    text-decoration: none;
    

    & > a {
      border-radius: $border-radius;
      color: inherit;
      display: block;
      margin: 0 (-$unit-2);
      padding: $unit-1 $unit-2;
      text-decoration: none;
      
      &:focus,
      &:hover {
        background: $secondary-color;
        color: $primary-color;
      }
      &:active,
      &.active {
        background: $secondary-color;
        color: $primary-color;
      }
    }

    .form-checkbox,
    .form-radio,
    .form-switch {
      margin: $unit-h 0;
    }

    & + .menu-item {
      margin-top: $unit-1;
    }
  }

  .menu-badge {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .label {
      margin-right: $unit-2;
    }
  }
}

.btn.btn-link {
    background: transparent;
    border-color: transparent;
    color: #fff;
    a:visited {
    color: #fff;
  }
}
.btn.btn-link:focus, .btn.btn-link:hover, .btn.btn-link:active, .btn.btn-link.active {
    color: #fff;
}
`;

const Nav = () => {
  return (
    <SecondaryNav>
      <div className="es-c-navbar--secondary">
        <div className="navbar-wrapper">
          <div className="dropdownOne">
            <div className="dropdown">
              <a href="#" className="btn btn-link dropdown-toggle" tabIndex={0}>
              Test Account QA <i class="fas fa-angle-down"></i>
              </a>
              <ul className="menu">
                <li className="menu-item">
                  <a href="/">
                    <i className="icon icon-link" /> Items
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/">
                    <i className="icon icon-link" /> Payments
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="dropdownTwo">
            <div className="dropdown">
              <a href="#" className="btn btn-link dropdown-toggle" tabIndex={0}>
                EchoShip Test 1 <i class="fas fa-angle-down"></i>
              </a>
              <ul className="menu">
                <li className="menu-item">
                  <a href="/">
                    <i className="icon icon-link" /> User Profile
                  </a>
                </li>
                <li className="menu-item location">
                  <a href="/">
                  <Link to="/Settings/Location/">
                    Locations
                  </Link>
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/">
                    <i className="icon icon-link" /> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </SecondaryNav>
  )
}

export default Nav;