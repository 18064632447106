import React from 'react'
import styled from 'styled-components'
// Import Font Awesome Icons
import '../../styles/icons/css/icons.css'

// 💅
const ButtonAddAnotherEmail = styled.button `
  font-family: 'Open Sans';
  background-color: transparent;
  border: transparent;
  border-radius: 3px;
  color: #0033a1;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.1rem;
  padding: 0 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:focus,
    &:hover {
      color: #fff;
      outline: 0;
      background-color: #26c1fb;
      border-color: #26c1fb;
      text-decoration: none;
  }
`;

// 🕳
const Button = (props) => {
  return (
    <ButtonAddAnotherEmail icon="plus" onClick={props.onClick}> 
      <i class="fas fa-plus"></i> Add Another Email
    </ButtonAddAnotherEmail>
  )
}
export default Button