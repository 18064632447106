import React from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css';
import { Link } from 'gatsby'

import PrimaryButton from '../../components/Buttons/PrimaryButton.js'

import ExpandRateResult from '../../components/Misc/ExpandRateResult.js'

const CarrierResultsAccordionLink = styled.div`
  .es-c-accordion--carrier {
    // 🎛
    // 🎨
    border-top: 0.5px solid #bbbbbb;
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
      background-color: #f7f7f7;
    }
    // 🖋
    font-family: "Open Sans";
    color: #0033a1;
    // 📰
    display: grid;
    grid-template-rows: 20px 60px 0.2fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "t_title t_title t_title t_title c_carrier_price"
      "c_img t_transit_time t_est_delivery t_cargo_liability c_carrier_price"
      "c_more_accordion c_more_accordion c_more_accordion c_more_accordion c_more_accordion";

    .es-c-carrier--img {
      grid-area: c_img; // 🗺
      vertical-align: middle;
      .carrier-thumbnail {
        width: 60px !important;
      }
      img {
        left: 10px;
      }
    }
    .es-t-carrier--transit-time {
      grid-area: t_transit_time; // 🗺
      justify-self: center;
      .es-t-days {
        font-weight: 400;
        color: #141b1f;
        margin-bottom: 0;
        font-size: 18px;
        margin-top: 4px;
      }
      .es-t-direct {
        color: #777;
        font-size: 12px;
        white-space: nowrap;
        font-style: italic;
      }
    }
    .es-t-carrier--est-delivery {
      grid-area: t_est_delivery; // 🗺
      justify-self: center;
      .es-t-date {
        font-weight: 400;
        color: #141b1f;
        margin-bottom: 0;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .es-t-carrier--cargo-liability {
      grid-area: t_cargo_liability; // 🗺
      justify-self: center;
      p {
        color: #666;
        margin: 0px;
        margin-top: 4px;
        span {
          color: #666;
          font-weight: 400;
          color: #141b1f;
          margin-bottom: 0;
          margin-top: 4px;
        }
      }
    }
    .es-c-more-accordion {
      grid-area: c_more_accordion; // 🗺
      color: #0033a1;
      cursor: pointer;
      font-size: 12px;
    }
    .es-c-carrier--price {
      padding-top: 15px;
      grid-area: c_carrier_price; // 🗺
      justify-self: center;
      p {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: #2b363b;
        margin: 0px;
      }
    }
    .es-t-label {
      font-size: 12px;
      letter-spacing: 0.3px;
      color: #666;
      font-weight: 600;
      margin: 0px;
      p {
        margin-top: 4px;
      }
    }
    .fas.fa-chevron-circle-down {
      margin-right: 3px;
      padding-left: 5px;
    }
  }
`

const AccordionTitle = styled.div `
    grid-area: t_title; // 🗺
    font-size: 12px;
    text-transform: uppercase;
    color: #2b363b;
    padding-bottom: 6px;
    padding-left: 10px;
`;


const AccordionComponent = (props) => {
  return (
    <CarrierResultsAccordionLink>
      <div className="es-c-accordion--carrier">

        <AccordionTitle>{props.title}</AccordionTitle>
      
        <div className="es-c-carrier--image">
          <img
            className="carrier-thumbnail"
            src={props.image} 
          />
        </div>
        <div className="es-t-carrier--transit-time">
          <p className="es-t-label">Transit Time</p>
          <p className="es-t-days">{props.transitTime}</p>
          <p className="es-t-direct">{props.Direct}</p>
        </div>
        <div className="es-t-carrier--est-delivery">
          <p className="es-t-label">Estimated Delivery</p>
          <p className="es-t-date">{props.estimatedDelivery}</p>
        </div>
        <div className="es-t-carrier--cargo-liability">
          <div className="es-t-label">Cargo Liability</div>
          <p>
            <span>{props.priceNew}</span> New
          </p>
          <p>
            <span>{props.priceUsed}</span> Used
          </p>
        </div>
        <div className="es-c-carrier--price">
          <p>{props.carrierPrice}</p>
        <Link to="/quote/CarrierResultsTwo/">
          <PrimaryButton orange>Select</PrimaryButton>
        </Link>
        </div>
        <div className="es-c-more-accordion">
          <div className="accordion">
            <input
              type="checkbox"
              id={props.htmlFor}
              name="accordion-checkbox"
              hidden
            />
            <label className="accordion-header" htmlFor={props.htmlFor} >
              <i class="fas fa-chevron-circle-down"></i>
                More Info
            </label>
            <div className="accordion-body">
              <ExpandRateResult></ExpandRateResult>
            </div>
          </div>
        </div>
      </div>
    </CarrierResultsAccordionLink>
  )
}

export default AccordionComponent