import React from "react"
import styled from "styled-components"

const ExpandRateResult = styled.div`
  .es-c-expand-rate-result {
    // 🎛
    padding: 4px;
    // 🎨
    background-color: #e7edf0;
    // 🖋
    font-family: "Open Sans";

    .nav-link {
      background-color: #e7edf0;
      color: #141b1f;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      text-decoration: none;
    }
    .nav.nav-tabs {
      margin-top: 0;
    }
    .nav-link.active {
      background-color: #fff;
    }
    .tab-content {
      background-color: #fff;
    }
    .tab-content {
      padding-top: 20px;
    }
    .tab-content.tab-content-two {
      padding: 20px;
    }
    .container {
      margin-left: 0px;
      margin-right: 0px;
    }
    .nav-tabs .nav-link {
      border: none;
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
    }
    .nav-tabs {
      border-bottom: 0.05rem solid #e7edf0;
    }
  }

  .es-c-expand-rate-result {
    .es-t-title {
      font-size: 12px;
      color: #666;
      margin-bottom: 15px;
    }
    .es-t-info {
      color: #141b1f;
      font-size: 12px;
    }
  }

  .ExpandRateResultGrid {
    // 📰
    display: grid;
    grid-template-rows: 125px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "sg_one sg_two ";
    .GridSectionOne {
      grid-area: sg_one; // 🗺
      padding-left: 20px;
    }
    .GridSectionTwo {
      grid-area: sg_two; // 🗺
    }
  }
  .tab {
    border-bottom: none;
  }
  .tab-item {
    padding-right: 15px;
    padding-left: 15px;
    color: #141b1f;
    font-weight: 700;
  }
  .tab .tab-item a:focus,
  .tab .tab-item a:hover {
    color: #141b1f;
  }
  .tab-item.active {
    background-color: white;
    border-bottom: none !important;
    a {
      border-bottom-color: none;
    }
  }
  ul {
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-left: 0px;
  }
  li {
    margin-bottom: 0px;
  }
  .tab .tab-item.active a,
  .tab .tab-item a.active {
    border-bottom-color: white !important;
    color: #141b1f;
  }

  .tab-pane {
    display: none;
  }
  .tab-pane.active {
    display: block;
  }

  .CostHeader {
    th {
      font-size: 12px;
      color: #666;
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 400 !important;
    }
  }
  .CostRowTwo {
    td {
      color: #2b363b;
      font-size: 12px;
      line-height: 22px;
      font-family: "Open Sans", sans-serif;
      font-weight: 500 !important;
    }
  }
  .CostRowThree {
    td {
      font-weight: 500 !important;
      color: #2b363b;
      font-size: 12px;
      line-height: 22px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .CostRowFour {
    td {
      color: #2b363b;
      font-size: 12px;
      line-height: 22px;
      font-family: "Open Sans", sans-serif;
      font-weight: 500 !important;
    }
  }
  .CostRowFive {
    td {
      font-weight: 500 !important;
      color: #2b363b;
      font-size: 12px;
      line-height: 22px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .CostTotal {
    td {
      font-weight: 600 !important;
      color: #2b363b;
      font-size: 14px;
      line-height: 22px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .LiabilityHeader {
    th {
      font-size: 12px;
      color: #666;
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 400 !important;
    }
  }
  .LiabilityRowOne {
    td {
      font-size: 12px;
      color: #000;
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 400 !important;
    }
  }
  .LiabilityDisclaimer {
    .LiabilityDisclaimerTitle {
      letter-spacing: 0.3px;
      color: #666;
      color: 12px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400 !important;
    }
    .LiabilityDisclaimerDescription {
      font-size: 12px;
      color: #000;
      margin: 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 400 !important;
    }
  }
`

function showTab(e) {
  e.preventDefault()
  var this_tab = e.target
  var tab_id = this_tab.hash.replace("#", "")
  document.querySelectorAll(".tab-item").forEach(function(el, i) {
    el.classList.remove("active")
  })
  this_tab.parentNode.classList.add("active")
  document.querySelectorAll(".tab-pane").forEach(function(el, i) {
    el.classList.toggle("active", el.id === tab_id)
  })
}

const EchoShipMiscComponent = () => {
  return (
    <ExpandRateResult>
      <div
        className="es-c-expand-rate-result"
        data-component="ExpandRateResult"
      >
        <ul class="tab" id="terminals-tab">
          <li class="tab-item active">
            <a href="#terminals" onClick={showTab}>
              Terminals
            </a>
          </li>
          <li class="tab-item" id="costs-tab">
            <a href="#costs" onClick={showTab}>
              Costs
            </a>
          </li>
          <li class="tab-item" id="liability-tab">
            <a href="#liability" onClick={showTab}>
              Liability
            </a>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane active"
            id="terminals"
            role="tabpanel"
            aria-labelledby="terminals-tab"
          >
            <div className="ExpandRateResultGrid">
              <div className="GridSectionOne">
                <p className="es-t-title">Origin Terminal</p>
                <p className="es-t-info">
                  <strong>CHICAGO - IL</strong>
                  <br />
                  600 W Chicago Ave
                  <br />
                  Chicago, IL 60610
                  <br />
                  800-354-7993
                </p>
              </div>

              <div className="GridSectionTwo">
                <p className="es-t-title">Destination Terminal</p>
                <p className="es-t-info">
                  <strong>LONG BEACH - CA</strong>
                  <br />
                  100 W Broadway
                  <br />
                  Long Beach, CA 90802
                  <br />
                  562-279-1800
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content tab-content-two" id="secondtab">
          <div
            className="tab-pane"
            id="costs"
            role="tabpanel"
            aria-labelledby="costs-tab"
          >
            <table cellpadding="0" cellspacing="0">
              <tr className="CostHeader">
                <th>
                  <b>Description</b>
                </th>
                <th>
                  <b>Rate Type</b>
                </th>
                <th>
                  <b>Rate</b>
                </th>
                <th>
                  <b>Amount</b>
                </th>
              </tr>

              <tr className="CostRowTwo">
                <td>Linehaul</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>$147.59</td>
              </tr>

              <tr className="CostRowThree">
                <td>Fuel Surcharge</td>
                <td>Percent</td>
                <td>24.50%</td>
                <td>$36.16</td>
              </tr>

              <tr className="CostRowFour">
                <td>Additional Services</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>$43.49</td>
              </tr>
              <tr className="CostRowFive">
                <td>State Fee</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>$43.49</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>

              <tr className="CostTotal">
                <td>Total</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>$227.24</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="tab-content tab-content-two" id="thirdtab">
          <div
            className="tab-pane"
            id="liability"
            role="tabpanel"
            aria-labelledby="liability-tab"
          >
            <table cellpadding="0" cellspacing="0">
              <tr className="LiabilityHeader">
                <th>Description</th>
                <th>Weight</th>
                <th>Estimated Cargo Liability</th>
              </tr>
              <tr className="LiabilityRowOne">
                <td>Class XX @ FAK XX</td>
                <td>500 LBS</td>
                <td>$4,000.00 New / $50.00 Used</td>
              </tr>
            </table>

            <div className="LiabilityDisclaimer">
              <p className="LiabilityDisclaimerTitle">
                <strong>Disclaimer</strong>
              </p>
              <p className="LiabilityDisclaimerDescription">
                The Estimated Carrier Liability is based on numerous factors,
                including class, weight and product description provided and is
                an estimate only. Additionally, there may be recent changes that
                are not reflected in the figures above. Please be aware that the
                liability limits of LTL carriers become null and void when a
                volume/spot quote is used and/or if expedited service is
                applied. Generally, carrier liability for volume/spot
                quotes/expedited is lowered dramatically (for example, $1/lb).
                Additionally, for purposes of carrier liability, used goods are
                typically valued at a max of $0.10/lb. When FAK pricing is
                utilized, carrier liability will be limited to the lowest
                applicable class within the FAK range, regardless of actual
                class of freight claimed. Certain NMFC codes provide or declare
                a lower released value for the commodity. If lower, the
                NMFC-specific released value is to be used. Please click here
                for further estimates of individual carrier item exclusions;
                again, these exclusions and values are for reference only and
                are not actively updated and, although such exclusions do not
                change very often, the exclusions on the Website may not be
                accurate. Please contact an Echo Sales Representative for
                information related to values, exclusions and liability for a
                specific shipment. In no event will Echo have any liability to
                Customer if the actual recovery differs from the Estimated
                Carrier Liability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ExpandRateResult>
  )
}

export default EchoShipMiscComponent
