import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';




// Import Components
import ButtonAddAnotherEmail from '../components/Buttons/ButtonAddAnotherEmail.js'

const MoleculePickupNotification = styled.div`
  margin-bottom: 25px;
  .es-t-page-title {
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
  }
  .email-remove {
    width: 45px;
    text-align: center;
    padding-top: 15px;
    color: red;
    cursor: pointer;
  }
  .form-input.input-lg {
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
  }
  .email-fieldset:first-of-type:last-of-type a{
    display: none !important;
  }
`; 

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      fields: [{email: ""}]
    }
  }

  handleEmailChange(idx, evt) {
    const newFields = this.state.fields.map((field, sidx) => {
      if (idx !== sidx) return field;
      return { ...field, email: evt.target.value };
    });

    this.setState({ fields: newFields });
  };

  addEmail() {
    this.setState({
      fields: this.state.fields.concat([{ email: "" }])
    });
  };

  removeEmail(idx) {
    this.setState({
      fields: this.state.fields.filter((s, sidx) => idx !== sidx)
    });
  };

  render(){
    return(
      <div className="pickup-notification-form" id="emailForm">
        {
          this.state.fields.map((field, index) => (
            <EmailField id={index} key={index} onClick={() => this.removeEmail(index)} />
          ))
        }
        <ButtonAddAnotherEmail onClick={this.addEmail.bind(this)}>Add Another Email</ButtonAddAnotherEmail>
      </div>
    );
  }
}

function EmailField(props) {
    return (
      <div className="form-group es-i-weight email-fieldset d-flex flex-row">
        <input
          className="form-input input-lg email-input"
          type="text"
          placeholder=""
          id={"input_" + props.index}
        />
        <a className="fa fa-times email-remove" onClick={props.onClick}></a>
      </div>
    );
  }

const EchoShipMiscComponent = () => {
  return (
    <MoleculePickupNotification>
      <div className="sg-pickup-notification">
        <div className="es-t-page-title">Pickup Notification</div>


        <EmailForm />
      </div>
    </MoleculePickupNotification>  
  )
}

export default EchoShipMiscComponent;