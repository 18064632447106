import React from "react"
import styled from "styled-components"
import "../styles/spectre.css"

import SwitchNewUsed from "../InputComponents/SwitchNewUsed.js"
const FilterGroupComponent = styled.div`
  input {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .CarrierOptions {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .form-group.es-i-sort-by {
    padding-bottom: 6px;
  }
  .form-group.es-i-est-transit-time {
    margin-top: 8px;
    margin-bottom: 10px;
  }
`
const FilterDivider = styled.div`
  border-bottom: 0.5px solid #bbb;
  border-top: 0.5px solid #bbb;
`
const EchoShipGroupComponent = () => {
  return (
    <FilterGroupComponent>
      <div className="es-c-filter">
        <div className="form-group es-i-sort-by">
          <label className="form-label" htmlFor="input-example-1">
            Sort By
          </label>
          <select className="form-select select-lg">
            <option>Price(Low to High)</option>
            <option>Carrier Name (A to Z)</option>
            <option>Transit Time (Low to High)</option>
            <option>Liability Amount (New Items, High to Low)</option>
          </select>
        </div>
        <FilterDivider />
        <div className="form-group es-i-est-transit-time">
          <label className="form-label">
            Estimated Transit Time
          </label>
          <label>
            <input type="checkbox" /> 1 Days - Fri, Feb 15
          </label>
          <br />
          <label>
            <input type="checkbox" /> 2 Days - Mon, Feb 18
          </label>
          <br />
          <label>
            <input type="checkbox" /> 3 Days - Tue, Feb 19{" "}
          </label>
          <br />

          <label>
            <input type="checkbox" /> 4 Days - Wed, Feb 20
          </label>
          <br />
          <label>
            <input type="checkbox" /> 5 Days - Thu, Feb 21{" "}
          </label>
          <br />
          <label>
            <input type="checkbox" /> 6 Days - Fri, Feb 22{" "}
          </label>
          <br />
        </div>
        <SwitchNewUsed />
        <div className="form-group es-i-show-misc">
          <label>
            <input className="CarrierOptions" type="checkbox" /> Lowest Price by
            Transit Day
          </label>
          <br />
          <FilterDivider />
          <label>
            <input className="CarrierOptions" type="checkbox" /> Show Direct
            Carriers Only <br />
          </label>
          <FilterDivider />
          <label>
            <input className="CarrierOptions" type="checkbox" /> Show Guarenteed
            Delivery Only <br />
          </label>
          <FilterDivider />
        </div>
        <div className="form-group es-i-carrier">
          <label className="form-label">
            Carrier
          </label>

          <label>
            <input type="checkbox" /> ABF Freight Systems
          </label>
          <br />
          <label>
            <input type="checkbox" /> CLEAR LANE FREIGHT SYSTEMS, LLC
          </label>
          <br />
          <label>
            <input type="checkbox" /> Central Freight Lines, Inc.
          </label>
          <br />

          <label>
            <input type="checkbox" /> Central Transport International
          </label>
          <br />

          <label>
            <input type="checkbox" /> DOT-Line Transportation
          </label>
          <br />

          <label>
            <input type="checkbox" /> Daylight Transport
          </label>
          <br />

          <label>
            <input type="checkbox" /> Estes Express
          </label>
          <br />

          <label>
            <input type="checkbox" /> FRONTLINE CARRIER SYSTEMS (USA) INC.
          </label>
          <br />

          <label>
            <input type="checkbox" /> FedEx Economy
          </label>
          <br />

          <label>
            <input type="checkbox" /> FedEx Priority
          </label>
          <br />

          <label>
            <input type="checkbox" /> Forward Air Inc - FWDA
          </label>
          <br />

          <label>
            <input type="checkbox" /> Old Dominion Freight Line, Inc.
          </label>
          <br />

          <label>
            <input type="checkbox" /> Pitt Ohio Express
          </label>
          <br />

          <label>
            <input type="checkbox" /> R and L Carriers
          </label>
          <br />

          <label>
            <input type="checkbox" /> RJW TRANSPORT, INC.
          </label>
          <br />

          <label>
            <input type="checkbox" /> Roadrunner Dawes Freight Systems Inc.
          </label>
          <br />

          <label>
            <input type="checkbox" /> Saia Motor Freight Line
          </label>
          <br />

          <label>
            <input type="checkbox" /> The Custom Companies, Inc.
          </label>
          <br />

          <label>
            <input type="checkbox" /> UPS Freight
          </label>
          <br />
          <label>
            <input type="checkbox" /> UPS Freight Trade Show
          </label>
          <br />
          <label>
            <input type="checkbox" /> XPO Logistics Freight Inc
          </label>
          <br />
          <label>
            <input type="checkbox" /> YRC - Trade Show Program
          </label>
          <br />
          <label>
            <input type="checkbox" /> YRC Freigh
          </label>
          <br />
        </div>
      </div>
    </FilterGroupComponent>
  )
}

export default EchoShipGroupComponent
